import * as Urls from '../Routing/Urls';
import { createOvertimeStatusesFilterQuery } from '../components/Overtime/OvertimeManage/OvertimeManageUtils';
import { createPaymentAdjustmentStatusesFilterQuery } from '../components/PaymentAdjustment/PaymentAdjustmentManage/PaymentAdjustmentManageUtils';
import { createVacationStatusesFilterQuery } from '../components/Vacations/VacationsManage/VacationsManageUtils';
import { createExcuseStatusesFilterQuery } from '../components/Excuses/ExcusesManage/ExcusesManageUtils';
import * as Constants from './Constants';
import {
  catchRequestError,
  createFilterQueryByFields,
  createStatusesQuery,
} from './Helpers';
import QueryString from 'qs';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { STATUSES_LOANS } from '../components/Loans/consts';
import { createLoansStatusesFilterQuery } from '../components/Loans/List/utils';
import { createRecurringPaymentStatusesFilterQuery } from '../components/RecurringPayments/RecurringPaymentsManage/RecurringPaymentsManageUtils';
import { createNursingScheduleStatusesFilterQuery } from '../components/Nursing/NursingSchedule/NursingScheduleView/NursingScheduleViewUtils';
import axios from 'axios';
import { EVALUATION_SHOW_ACTIONS } from '../components/Evaluations/helpers/consts';

export const getCurrentUser = () => {
  const userToken =
    Constants.userData.token || localStorage.getItem('userToken');
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/me`,
    headers: { Authorization: `Bearer ${userToken}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getUsers = (usersList) => {
  const request = axios({
    method: 'get',
    url: `${Urls.backendUrl}/users?list=${usersList}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });

  return catchRequestError(request);
};

export const getUsersMinimalInfo = () => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/users?details_level=minimum`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getTeams = () => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/teams`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getUserStatusUpdates = (userId) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}${Constants.Pages.StatusUpdates.path(userId)}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getManagers = () => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/managers`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getStaff = () => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/staff`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getAllowedTypes = () => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/paymentAdjustments/types`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getVacationsTypes = () => {
  const request = axios({
    method: 'get',
    url: `${Urls.backendUrl}/vacation-types`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });

  return catchRequestError(request);
};

export const getRequestedUser = (userId) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/users/${userId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getUserOvertimeRequests = (userId, filterFields = null) => {
  let url = `${Urls.backendUrl}/users/${userId}/overtime?`;
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getUserVacations = (userId, filterFields = null) => {
  let url = `${Urls.backendUrl}/users/${userId}/vacations?`;
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

// This is the new endpoint for getting the vacations for a user
export const getUserVacationBalances = (
  userId,
  Statuses,
  year = dayjs().year(),
) => {
  let url = `${Urls.backendUrl}/users/${userId}/vacations/balance?`;
  const filterObj = { 'status[]': Statuses, year };
  const queryParams = createFilterQueryByFields(filterObj);
  url += `${queryParams}`;

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getUserExcuses = (userId, filterFields = null) => {
  let url = `${Urls.backendUrl}/users/${userId}/excuses?`;
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getUserExcusesBalance = (userId, dateString) => {
  const date = dayjs(dateString);
  const monthFirstDay = date.startOf('month').format('YYYY-MM-DD');
  const monthLastDay = date.endOf('month').format('YYYY-MM-DD');

  const filterParams = {
    from: monthFirstDay,
    to: monthLastDay,
  };

  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/users/${userId}/excuses/balance?${QueryString.stringify(filterParams)}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getRoles = () => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/roles`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const formRequest = (path, method, formData) => {
  return axios({
    method,
    url: `${Urls.backendUrl}/${path}`,
    data: formData,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  });
};

export const addUser = (formData) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/users/`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateUserInfo = (userId, formData) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/users/${userId}`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const addStatusUpdate = (userId, formData) => {
  return axios({
    method: 'post',
    url: Urls.backendUrl + Constants.Pages.StatusUpdates.path(userId),
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateStatusUpdate = (userId, statusUpdateId, formData) => {
  return axios({
    method: 'post',
    url:
      Urls.backendUrl +
      Constants.Pages.StatusUpdates.path(userId) +
      `/${statusUpdateId}`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  });
};

export const deleteStatusUpdate = (userId, statusUpdateId) => {
  return axios({
    method: 'delete',
    url:
      Urls.backendUrl +
      Constants.Pages.StatusUpdates.path(userId) +
      `/${statusUpdateId}`,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  });
};

export const getOvertimeRequests = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  let url = userId
    ? `${Urls.backendUrl}/users/${userId}/overtime?`
    : `${Urls.backendUrl}/overtime?`;

  // adding filter params to request query.
  if (tableType !== null) {
    const statusesQueryString = createOvertimeStatusesFilterQuery(tableType);
    url += `${statusesQueryString}`;
  }
  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getPaymentAdjustmentRequests = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  let url = userId
    ? `${Urls.backendUrl}/users/${userId}/paymentAdjustments?`
    : `${Urls.backendUrl}/paymentAdjustments?`;

  // adding filter params to request query.
  if (tableType !== null) {
    const callerComponent = userId
      ? Constants.CallerComponent.View
      : Constants.CallerComponent.Manage;
    const statusesQueryString = createPaymentAdjustmentStatusesFilterQuery(
      tableType,
      Constants.userData.role.name,
      callerComponent,
    );
    url += `${statusesQueryString}`;
  }

  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getUserPaymentAdjustments = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  // Add extra filtration parameter to get user related records
  filterFields.filtration = 'user';

  return getPaymentAdjustmentRequests(
    tableType,
    page,
    rowsPerPage,
    filterFields,
    userId,
  );
};

export const getUserPaymentAdjustmentRequests = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  // Add extra filtration parameter to get user requested records
  filterFields.filtration = 'requested';

  return getPaymentAdjustmentRequests(
    tableType,
    page,
    rowsPerPage,
    filterFields,
    userId,
  );
};

export const getVacationRequests = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
) => {
  let url = `${Urls.backendUrl}/vacations?`;
  // adding filter params to request query.
  if (tableType !== null) {
    const statusesQueryString = createVacationStatusesFilterQuery(
      tableType,
      Constants.userData.role.name,
    );
    url += `${statusesQueryString}`;
  }
  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getVacationsReport = (
  page = null,
  rowsPerPage = null,
  filterFields = null,
) => {
  let url = `${Urls.backendUrl}/vacations-report?`;
  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getSelfPayroll = (filterFields = null) => {
  let url = `${Urls.backendUrl}/payroll?`;
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const createPayrollInvoice = (formData) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/payroll-history`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  });
};

export const listPayrollHistory = (
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  let url = userId
    ? `${Urls.backendUrl}/users/me/payroll-history?`
    : `${Urls.backendUrl}/payroll-history?`;

  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getNursingHours = (
  page = null,
  rowsPerPage = null,
  filterFields = null,
) => {
  let url = `${Urls.backendUrl}/nursing-hours?`;
  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getNursingSchedule = (
  page = null,
  rowsPerPage = null,
  filterFields = null,
) => {
  let url = `${Urls.backendUrl}/nursing-schedule?`;
  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getExcuseRequests = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  let url = userId
    ? `${Urls.backendUrl}/users/${userId}/excuses?`
    : `${Urls.backendUrl}/excuses?`;
  // adding filter params to request query.
  if (tableType !== null) {
    const callerComponent = userId
      ? Constants.CallerComponent.View
      : Constants.CallerComponent.Manage;
    const statusesQueryString = createExcuseStatusesFilterQuery(
      tableType,
      Constants.userData.role.name,
      callerComponent,
    );
    url += `${statusesQueryString}`;
  }

  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const filterOvertimeRequests = (filterFields) => {
  const filterQueryString = createFilterQueryByFields(filterFields);
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/overtime?${filterQueryString}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const filterPaymentAdjustmentRequests = (filterFields) => {
  const filterQueryString = createFilterQueryByFields(filterFields);
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/paymentAdjustments?${filterQueryString}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const filterVacationRequests = (filterFields) => {
  const filterQueryString = createFilterQueryByFields(filterFields);
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/vacations?${filterQueryString}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const filterExcuseRequests = (filterFields) => {
  const filterQueryString = createFilterQueryByFields(filterFields);
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/excuses?${filterQueryString}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getOvertimeRequest = (requestId) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/overtime/${requestId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getPaymentAdjustmentRequest = (requestId) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/paymentAdjustments/${requestId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getVacationRequest = (requestId) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/vacations/${requestId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getExcuseRequest = (requestId) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/excuses/${requestId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getWeekends = () => {
  const request = axios({
    method: 'get',
    url: `${Urls.backendUrl}/weekends`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });

  return catchRequestError(request);
};

export const getHierarchy = (treeType) => {
  const request = axios({
    method: 'get',
    url: `${Urls.backendUrl}/get-hierarchy?treeType=${treeType}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });

  return catchRequestError(request);
};

export const getHolidays = () => {
  const request = axios({
    method: 'get',
    url: `${Urls.backendUrl}/holidays`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });

  return catchRequestError(request);
};

export const deleteHoliday = (holidayId) => {
  return axios({
    method: 'delete',
    url: `${Urls.backendUrl}/holidays/${holidayId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res;
  });
};

export const deleteOvertime = (userId, overtimeId) => {
  return axios({
    method: 'delete',
    url: `${Urls.backendUrl}/users/${userId}/overtime/${overtimeId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res;
  });
};

export const deletePaymentAdjustment = (userId, paymentAdjustmentId) => {
  return axios({
    method: 'delete',
    url: `${Urls.backendUrl}/users/${userId}/paymentAdjustments/${paymentAdjustmentId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res;
  });
};

export const deleteVacation = (userId, vacationId) => {
  return axios({
    method: 'delete',
    url: `${Urls.backendUrl}/users/${userId}/vacations/${vacationId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res;
  });
};

export const deleteExcuse = (userId, excuseId) => {
  return axios({
    method: 'delete',
    url: `${Urls.backendUrl}/users/${userId}/excuses/${excuseId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res;
  });
};

export const fetchFile = async (filePath) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${Urls.backendUrl}/files/${filePath}`,
      headers: { Authorization: `Bearer ${Constants.userData.token}` },
      responseType: 'blob',
    });
    // Handle the file (e.g., display or download)
    // Example: Displaying a PDF file
    const url = URL.createObjectURL(response.data);
    return url;
  } catch (error) {
    // Handle unauthorized or other errors
    console.error('Error fetching file:', error);
  }
};

export const handleRequestErrors = (err) => {
  console.error(err);

  let errorMsg = '';

  if (err.response) {
    switch (err.response.status) {
      case 401:
        localStorage.removeItem('userToken');
        window.location.href = Constants.Pages.Login.path();
        break;
      default:
        errorMsg = err.response.data.error || err.response.data.message;
        toast(errorMsg || JSON.stringify(err.response.data), {
          type: 'error',
        });
    }
  }
};

export const getUserById = (userId) => {
  const userToken =
    Constants.userData.token || localStorage.getItem('userToken');
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/users/${userId}`,
    headers: { Authorization: `Bearer ${userToken}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const requestLoans = (formData, userId) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/users/${userId}/loans`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  });
};

export const requestOvertime = (formData, userId) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/users/${userId}/overtime`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  });
};

export const requestPaymentAdjustment = (formData, userId) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/users/${userId}/paymentAdjustments`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  });
};

export const loginAsRequest = (userId) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/users/${userId}/login-as`,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  });
};

export const getWorkLogsWithExtraInfo = (filterParams) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/worklogs-extra-info?${QueryString.stringify(filterParams)}`,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  }).then((res) => {
    return res.data.data;
  });
};

export const createWorkLogsExtraInfo = (formData) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/worklogs-extra-info`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateWorkLogsExtraInfo = (worklogId, formData) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/worklogs-extra-info/${worklogId}`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const requestRecurringPayment = (formData, userId) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/users/${userId}/recurring-payments`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  });
};

export const deleteRecurringPayment = (userId, requestId) => {
  return axios({
    method: 'delete',
    url: `${Urls.backendUrl}/users/${userId}/recurring-payments/${requestId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res;
  });
};

export const getRecurringPaymentRequests = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  let url = userId
    ? `${Urls.backendUrl}/users/${userId}/recurring-payments?`
    : `${Urls.backendUrl}/recurring-payments?`;

  // adding filter params to request query.
  if (tableType !== null) {
    const statusesQueryString =
      createRecurringPaymentStatusesFilterQuery(tableType);
    url += `${statusesQueryString}`;
  }
  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getRecurringPaymentRequest = (requestId) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/recurring-payments/${requestId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const requestNursingSchedule = (formData, userId) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/users/${userId}/nursing-schedule`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  });
};

export const deleteNursingSchedule = (userId, requestId) => {
  return axios({
    method: 'delete',
    url: `${Urls.backendUrl}/users/${userId}/nursing-schedule/${requestId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res;
  });
};

export const getNursingScheduleRequests = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  let url = userId
    ? `${Urls.backendUrl}/users/${userId}/nursing-schedule?`
    : `${Urls.backendUrl}/nursing-schedule?`;

  // adding filter params to request query.
  if (tableType !== null) {
    const statusesQueryString =
      createNursingScheduleStatusesFilterQuery(tableType);
    url += `${statusesQueryString}`;
  }
  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getNursingScheduleRequest = (requestId) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/nursing-schedule/${requestId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const getUserLoans = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  let url = userId
    ? `${Urls.backendUrl}/users/${userId}/loans?`
    : `${Urls.backendUrl}/loans?`;

  // adding filter params to request query.
  if (tableType !== null) {
    const statusesQueryString = createLoansStatusesFilterQuery(tableType);
    url += `${statusesQueryString}`;
  }
  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

// export const getUserAttendances = (
//     tableType = null,
//     page = null,
//     rowsPerPage = null,
//     filterFields = null,
//     userId = null,
// ) => {
//   let url = userId
//       ? `${Urls.backendUrl}/users/${userId}/attendances?`
//       : `${Urls.backendUrl}/attendances?`;
//
//   // adding filter params to request query.
//   if (tableType !== null) {
//     const statusesQueryString = createLoansStatusesFilterQuery(tableType);
//     url += `${statusesQueryString}`;
//   }
//   if (page !== null) {
//     url += `page=${page + 1}&`;
//   }
//   if (rowsPerPage !== null) {
//     url += `per_page=${rowsPerPage}&`;
//   }
//   if (filterFields !== null) {
//     const filterQueryString = createFilterQueryByFields(filterFields);
//     url += `${filterQueryString}`;
//   }
//
//   return axios({
//     method: 'get',
//     url,
//     headers: { Authorization: `Bearer ${Constants.userData.token}` },
//   }).then((res) => {
//     return res.data;
//   });
// };

export const getLoanRequest = (requestId) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/loans/${requestId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

// export const getAttendancesRequest = (requestId) => {
//   return axios({
//     method: 'get',
//     url: `${Urls.backendUrl}/attendances/${requestId}`,
//     headers: { Authorization: `Bearer ${Constants.userData.token}` },
//   }).then((res) => {
//     return res.data.data;
//   });
// };

export const deleteLoan = (userId, requestId) => {
  return axios({
    method: 'delete',
    url: `${Urls.backendUrl}/users/${userId}/loans/${requestId}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res;
  });
};

export const getRunningLoans = (
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  let url = userId
    ? `${Urls.backendUrl}/users/${userId}/loans?`
    : `${Urls.backendUrl}/loans?`;

  // adding filter params to request query.
  const statusesQueryString = createStatusesQuery([STATUSES_LOANS.Approved]);
  url += `${statusesQueryString}`;

  // Add running flag to the params.
  url += `running=true&`;

  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getLoanRequests = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
  userId = null,
) => {
  let url = userId
    ? `${Urls.backendUrl}/users/${userId}/loans?`
    : `${Urls.backendUrl}/loans?`;

  // adding filter params to request query.
  if (tableType !== null) {
    const statusesQueryString = createLoansStatusesFilterQuery(tableType);
    url += `${statusesQueryString}`;
  }
  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const getMonthMidDay = (month) => {
  return axios({
    method: 'get',
    url: `${Urls.backendUrl}/setting/month-mid-day/${month}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });
};

export const setMonthMidDay = (formData) => {
  return axios({
    method: 'post',
    url: `${Urls.backendUrl}/setting/month-mid-day`,
    data: formData,
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getPaymentReport = (formData) => {
  let url = `${Urls.backendUrl}/payments/report?`;
  const filterQueryString = createFilterQueryByFields(formData);
  url += `${filterQueryString}`;

  return axios({
    method: 'get',
    url,
    responseType: 'arraybuffer',
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res;
  });
};

export const getEvaluation = (evaluationId, action) => {
  const request = axios({
    method: 'get',
    url: `${Urls.backendUrl}/evaluations/${evaluationId}?action=${action}`,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data.data;
  });

  return request;
};

export const getEvaluations = (
  tableType = null,
  page = null,
  rowsPerPage = null,
  filterFields = null,
) => {
  let url = `${Urls.backendUrl}/evaluations?`;

  // adding filter params to request query.
  if (tableType !== null) {
    url += `status=${tableType}&`;
  }
  if (page !== null) {
    url += `page=${page + 1}&`;
  }
  if (rowsPerPage !== null) {
    url += `per_page=${rowsPerPage}&`;
  }
  if (filterFields !== null) {
    const filterQueryString = createFilterQueryByFields(filterFields);
    url += `${filterQueryString}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${Constants.userData.token}` },
  }).then((res) => {
    return res.data;
  });
};

export const submitEvaluationAnswers = (evaluationId, formData, actionType) => {
  const url =
    actionType === EVALUATION_SHOW_ACTIONS.Edit
      ? `${Urls.backendUrl}/evaluations/${evaluationId}`
      : `${Urls.backendUrl}/evaluations/${evaluationId}/submit`;

  const method = actionType === EVALUATION_SHOW_ACTIONS.Edit ? 'put' : 'post';

  return axios({
    method,
    url,
    data: { data: formData },
    headers: {
      Authorization: `Bearer ${Constants.userData.token}`,
    },
  });
};
